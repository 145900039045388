<template>
  <div>
    <div class="formLabel fl">
      <span class="icon" v-if="showIcon">*</span>
      <span>{{ label }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'formLabel',
  props: {
    label: String, // 标题
    showIcon: Boolean || false // 是否显示图标
  }
}
</script>

<style scoped lang="less"></style>
