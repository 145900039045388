<template>
  <div class="pagePadding">
    <div class="fromDiv">
      <!-- <div class="formLine">
        <FormLabel label="发票单号"></FormLabel>
        <div class="allWidth fl">
          <i-input placeholder="请输入" class="iviewIptWidth307" v-model="billForm.invoice_no"></i-input>
        </div>
      </div> -->
      <div class="formLine borderTopNone">
        <FormLabel label="开票时间"></FormLabel>
        <div class="allWidth">
          <DatePicker :disabled="type == '2'" type="date" class="iviewIptWidth307" placeholder="请选择" v-model="billForm.invoice_time" :options="options"></DatePicker>
        </div>
      </div>
      <div class="formLine borderTopNone">
        <FormLabel label="发票"></FormLabel>
        <div class="allWidth fl">
          <Input readonly class="iviewIptWidth307" placeholder="仅支持大小不超过2M的jpg.gif.png等格式的图片">
            <span slot="append" class="finger">
              <Upload :disabled="type == '2'" id="iviewUp" :on-success="handleSuccessQuill" :format="['jpg', 'jpeg', 'png', 'gif']" :headers="header" name="richTextAccessory" :max-size="2048" :action="url" :on-format-error="onFormatError" multiple :show-upload-list="false"> 上传 </Upload>
            </span>
          </Input>
          <Badge v-for="(item, index) in uploadList" :key="index" style="margin-left: 10px">
            <span closable @click="showImg(item.invoice_url, index)" class="imgTit">{{ item.invoice_name }}</span>
            <Icon v-if="type != '2'" type="md-close-circle" slot="count" color="#ed4014" size="12" @click="sureDelete(index)" style="cursor: pointer" />
          </Badge>
        </div>
        <Modal title="发票展示" v-model="visible" draggable :closable="true" @on-cancel="cancel" width="600px">
          <img :src="imgUrl" v-if="visible" style="width: 100%" />
        </Modal>
      </div>
      <div class="formLine borderTopNone heightAuto">
        <FormLabel label="关联采购订单" style="background: #f8f8f9"></FormLabel>
        <div class="allWidth fl">
          <div class="uploading iviewIptWidth1075">
            <span class="itemSpan" v-for="(item, index) in showShopSelect" :key="index">
              <span>{{ item.order_number }}</span>
              <i v-if="type != '2'" @click="isDeleteWare = [true, index]">x</i>
            </span>
            <i class="tip" v-if="showShopSelect.length == 0">请选择</i>
          </div>
          <span class="pageBtn finger btnSure marginLeft20" v-if="type !== '2'" @click="showShopTable">查询</span>
        </div>
      </div>
      <div class="formLine borderTopNone heightAuto">
        <FormLabel label="采购退出通知单" style="background: #f8f8f9"></FormLabel>
        <div class="allWidth fl">
          <div class="uploading iviewIptWidth1075">
            <span class="itemSpan" v-for="(item, index) in showReturnSelect" :key="index">
              <span>{{ item.order_number }}</span>
              <i v-if="type != '2'" @click="isDeleteReturn = [true, index]">x</i>
            </span>
            <i class="tip" v-if="showReturnSelect.length == 0">请选择</i>
          </div>
          <span class="pageBtn finger btnSure marginLeft20" v-if="type !== '2'" @click="showReturnTable">查询</span>
        </div>
      </div>
      <div class="formLine borderTopNone">
        <FormLabel label="发票金额"></FormLabel>
        <div class="allWidth fl">
          <Input placeholder="请输入" class="iviewIptWidth307" type="number" v-model="billForm.invoice_amount" @input="changeNum" disabled></Input>
          <span style="margin-left: 8px">元</span>
        </div>
      </div>
    </div>
    <div class="btnGroup">
      <span class="pageBtn finger btnReset" @click="goBack">返回</span>
      <span class="pageBtn finger btnSure marginLeft20" v-if="type !== '2'" @click="submitVisible = true">确认</span>
    </div>
    <tips-component showModal="delete" v-if="deleteVisible" @cancleBtn="deleteVisible = false" @sureBrn="deleteImg()"></tips-component>
    <tips-component showModal="post" v-if="submitVisible" @cancleBtn="submitVisible = false" @sureBrn="submitData()"></tips-component>

    <Modal v-model="selectShopModal" :footer-hide="true" title="关联采购订单" @on-ok="okPurchaseSelect" @on-cancel="clearPostSelect" width="60" ok-text="确认">
      <myTable :productList="selectShopList" :productData="shopSelect" :total="shopTotal" :pages="shopPages" @on-select="onSelect" @on-select-cancel="onSelectionCancel" @on-select-all="onSelectAll" @on-select-all-cancel="onSelectionAllCancel" @change-page="changeShopPage" totalText="条记录"></myTable>
      <div class="foot clearfix">
        <span class="pageBtn finger marginLeft20 btnSure fr" @click="okPurchaseSelect">确定</span>
        <span class="pageBtn finger btnCancle fr" @click="clearPostSelect">取消</span>
      </div>
    </Modal>

    <Modal v-model="selectReturnModal" :footer-hide="true" title="采购退出通知单"  @on-cancel="clearReturnPostSelect" width="60" ok-text="确认">
      <myTable :productList="selectReturnList" :productData="returnSelect" :total="returnTotal" :pages="returnPages" @on-select="onReturnSelect" @on-select-cancel="onReturnSelectionCancel" @on-select-all="onReturnSelectAll" @on-select-all-cancel="onReturnSelectionAllCancel" @change-page="changeReturnPage" totalText="条记录"></myTable>
      <div class="foot clearfix">
        <span class="pageBtn finger marginLeft20 btnSure fr" @click="okReturnPurchaseSelect">确定</span>
        <span class="pageBtn finger btnCancle fr" @click="clearReturnPostSelect">取消</span>
      </div>
    </Modal>
    <!-- 确认采购订单删除-弹窗 -->
    <tips-component showModal="delete" v-if="isDeleteWare[0]" @cancleBtn="isDeleteWare = [false, undefined]" @sureBrn="deleteWare(isDeleteWare[1])"></tips-component>

    <!-- 确认采购退货单删除-弹窗 -->
    <tips-component showModal="delete" v-if="isDeleteReturn[0]" @cancleBtn="isDeleteReturn = [false, undefined]" @sureBrn="deleteReturnWare(isDeleteReturn[1])"></tips-component>
  </div>
</template>

<script>
import FormLabel from '@/components/formLabel'
import http from '@/service/baseUrl'
import tipsComponent from '@/components/tipsComponent'
import myTable from '@/components/table'
export default {
  components: {
    FormLabel,
    tipsComponent,
    myTable
  },
  name: 'billingDetails',
  data() {
    return {
      title: '',
      type: '1', // 1新增 2查看 3编辑
      bill_id: 0, // 发票id
      billForm: {
        invoice_no: '',
        invoice_time: '',
        invoice_amount: null,
        invoice_info: [],
        purchase_id_array: [],
        return_id_array: []
      },
      uploadList: [], // 图片展示列表
      imgUrl: '',
      imgIndex: 0,
      visible: false,
      url: '',
      header: {},
      deleteVisible: false /* 删除模态框显示 */,
      submitVisible: false /* 提交模态框显示 */,
      selectShopModal: false, // 查询采购订单模态框
      selectReturnModal: false, // 查询采购退货单模态框
      selectSaleModal: false,
      options: {
        disabledDate(date) {
          return date && date.valueOf() > Date.now()
        }
      },
      shopSelect: [], // 采购订单下拉表选项
      showShopSelect: [], // 显示采购订单下拉列表选项
      showSaleSelect: [], // 关联销售订单
      // 采购订单表头
      selectShopList: [
        {
          type: 'selection',
          width: 60,
          align: 'center'
        },
        {
          type: 'index',
          width: 75,
          title: '序号',
          align: 'center'
        },
        {
          title: '订单号',
          key: 'order_number',
          align: 'center'
        },
        {
          title: '产品',
          key: 'product_list',
          align: 'center'
        },
        {
          title: '总数量',
          key: 'total_number',
          align: 'center'
        },
        {
          title: '总金额',
          key: 'total_amount',
          align: 'center'
        },
        {
          title: '下单时间',
          key: 'insert_time',
          align: 'center'
        }
      ],
      postShopSelect: [], // 最后发送的采购订单数组
      allShopSelect: [], // 所有采购订单表
      shopTotal: 0,
      shopPages: {
        page: 1,
        rows: 10
      },
      saleTotal: 0,
      slaePages: {
        page: 1,
        rows: 10
      },
      // 是否删除关联的出库单
      isDeleteWare: [false, undefined],
      returnSelect: [], // 采购退货单下拉表选项
      SaleSelect: [],
      showReturnSelect: [], // 显示采购订单下拉列表选项
      // 采购订单表头
      selectReturnList: [
        {
          type: 'selection',
          width: 60,
          align: 'center'
        },
        {
          type: 'index',
          width: 75,
          title: '序号',
          align: 'center'
        },
        {
          title: '订单号',
          key: 'order_number',
          align: 'center'
        },
        {
          title: '产品',
          key: 'product_list',
          align: 'center'
        },
        {
          title: '总数量',
          key: 'total_number',
          align: 'center'
        },
        {
          title: '总金额',
          key: 'total_amount',
          align: 'center'
        },
        {
          title: '下单时间',
          key: 'insert_time',
          align: 'center'
        }
      ],
      selectSaleList: [],
      postReturnSelect: [], // 最后发送的采购订单数组
      allReturnSelect: [], // 所有采购订单表
      returnTotal: 0,
      returnPages: {
        page: 1,
        rows: 10
      },
      isDeleteReturn: [false, undefined] // 采购退货删除弹窗
    }
  },
  methods: {
    // 确认关联采购订单
    okPurchaseSelect() {
      this.billForm.purchase_id_array = []
      this.showShopSelect = []
      for (const item of this.postShopSelect) {
        this.billForm.purchase_id_array.push(item)
        this.showShopSelect.push(
          this.allShopSelect.find(val => {
            return item == val.order_number
          })
        )
      }
      console.log(this.allShopSelect)
      this.selectShopModal = false
    },
    // 点击取消的时候，清空postshopselect让未确定项复原
    clearPostSelect() {
      this.selectShopModal = false
      this.postShopSelect = []
    },
    // 选中某一项时触发
    onSelect(data, row) {
      let purchase_id = row.order_number
      this.postShopSelect.push(purchase_id)
      this.allShopSelect.push(row)
    },
    // 取消选中某一项时触发
    onSelectionCancel(data, row) {
      let index = this.postShopSelect.findIndex((val, index) => {
        return val == row.order_number
      })
      this.postShopSelect.splice(index, 1)
    },
    // 点击全选时触发
    async onSelectAll(datalist) {
      let data = {
        page: 1,
        rows: this.shopTotal,
        is_admin: 1,
        purchase_type: '1'
      }
      this.postShopSelect = []
      await this.$http.get(this.$api.getShopSelect, data).then(res => {
        if (res.status) {
          for (const item of res.data) {
            this.postShopSelect.push(item.order_number)
            item.insert_time = item.insert_time ? this.$moment.unix(item.insert_time).format('YYYY-MM-DD HH:mm:ss') : ''
          }
          this.allShopSelect = res.data
        }
      })
    },
    // 点击取消全选的时候
    onSelectionAllCancel(datalist) {
      this.postShopSelect = []
    },
    // 采购订单分页
    changeShopPage(e) {
      this.shopPages.page = e
      this.selectShop()
    },
    // 展示采购弹窗并并刷新
    showShopTable() {
      this.selectShopModal = true
      this.selectShop()
    },
    /* 采购订单表 */
    async selectShop() {
      let data = {
        page: this.shopPages.page,
        rows: this.shopPages.rows,
        is_admin: 1,
        purchase_type: '1'
      }
      if (this.type === '2') {
        // 当查看的时候
        data = {
          page: 1,
          rows: 1000,
          purchase_type: '1',
          invoice_detail_page: '1'
        }
      }
      // 如果是采购订单
      await this.$http.get(this.$api.getShopSelect, data).then(res => {
        if (res.status) {
          for (const item of res.data) {
            item.insert_time = item.insert_time ? this.$moment.unix(item.insert_time).format('YYYY-MM-DD HH:mm:ss') : ''
            if (
              this.billForm.purchase_id_array.some(val => {
                return val == item.order_number
              }) ||
              this.postShopSelect.some(val => {
                return val == item.order_number
              })
            ) {
              this.postShopSelect.push(item.order_number)
              this.$set(item, '_checked', true)
            }
          }
          this.shopSelect = res.data
          this.shopTotal = res.total
          this.postShopSelect = Array.from(new Set(this.postShopSelect))
        }
      })
    },
    // 1\------------------------------------------------------
    // 确认关联采购退货单
    okReturnPurchaseSelect() {
      this.billForm.return_id_array = []
      this.showReturnSelect = []
      for (const item of this.postReturnSelect) {
        this.billForm.return_id_array.push(item)
        this.showReturnSelect.push(
          this.allReturnSelect.find(val => {
            return item == val.order_number
          })
        )
      }
      this.selectReturnModal = false
    },
    // 点击取消的时候，清空postshopselect让未确定项复原
    clearReturnPostSelect() {
      this.selectReturnModal = false
      this.postReturnSelect = []
    },
    // 选中某一项时触发
    onReturnSelect(data, row) {
      let purchase_id = row.order_number
      this.postReturnSelect.push(purchase_id)
      this.allReturnSelect.push(row)
    },
    // 取消选中某一项时触发
    onReturnSelectionCancel(data, row) {
      let index = this.postReturnSelect.findIndex((val, index) => {
        return val == row.order_number
      })
      this.postReturnSelect.splice(index, 1)
    },
    // 点击全选时触发
    async onReturnSelectAll(datalist) {
      let data = {
        page: 1,
        rows: this.shopTotal,
        is_admin: 1,
        purchase_type: '2'
      }
      this.postReturnSelect = []
      await this.$http.get(this.$api.getShopSelect, data).then(res => {
        if (res.status) {
          for (const item of res.data) {
            this.postReturnSelect.push(item.order_number)
            item.insert_time = item.insert_time ? this.$moment.unix(item.insert_time).format('YYYY-MM-DD HH:mm:ss') : ''
          }
          this.allReturnSelect = res.data
        }
      })
    },
    // 点击取消全选的时候
    onReturnSelectionAllCancel(datalist) {
      this.postReturnSelect = []
    },
    // 采购订单分页
    changeReturnPage(e) {
      this.returnPages.page = e
      this.allReturnSelect.concat(this.returnSelect)
      this.selectReturn()
    },
    // 采购退货单
    async selectReturn() {
      let data = {
        page: this.returnPages.page,
        rows: this.returnPages.rows,
        is_admin: 1,
        purchase_type: '2'
      }
      if (this.type === '2') {
        // 当查看的时候
        data = {
          page: 1,
          rows: 1000,
          purchase_type: '2',
          invoice_detail_page: '1'
        }
      }
      await this.$http.get(this.$api.getShopSelect, data).then(res => {
        if (res.status) {
          for (const item of res.data) {
            item.insert_time = item.insert_time ? this.$moment.unix(item.insert_time).format('YYYY-MM-DD HH:mm:ss') : ''
            if (
              this.billForm.return_id_array.some(val => {
                return val == item.order_number
              }) ||
              this.postReturnSelect.some(val => {
                return val == item.order_number
              })
            ) {
              this.postReturnSelect.push(item.order_number)
              this.$set(item, '_checked', true)
            }
          }
          this.returnSelect = res.data
          this.returnTotal = res.total
          this.postReturnSelect = Array.from(new Set(this.postReturnSelect))
        }
      })
    },
    // 删除采购退货单
    deleteReturnWare(index) {
      this.showReturnSelect.splice(index, 1)
      this.postReturnSelect.splice(index, 1)
      this.billForm.return_id_array.splice(index, 1)
      this.$Message.success('删除成功')
      this.isDeleteReturn = [false, undefined]
    },
    // 2\-------------------------------------------------------------
    // 展示采购弹窗并并刷新
    // 3\--------------------------------------------------------------
    goBack() {
      this.$router.push('billingInformation')
    },
    /* 获取详细信息 */
    async getBillInformation() {
      if (this.type !== '1') {
        await this.$http.get(this.$api.getBillingInformationDetail, { invoice_no: this.bill_id }).then(res => {
          if (res.status) {
            let item = res.data
            let btime = item.invoice_time ? this.$moment.unix(item.invoice_time).format() : ''
            this.billForm.invoice_time = btime
            this.billForm.invoice_no = item.invoice_no
            this.billForm.invoice_amount = item.invoice_amount
            // 对图片进行操作
            if (item.invoice_info) {
              let imgList = item.invoice_info
              // 对得到的图片数组字段做处理，因为for循环的字段和得到的字段不一样
              for (const item of imgList) {
                item.file_name = item.invoice_name
                item.file_url = item.invoice_url
              }
              // 将得到的图片数组里的名字和路劲放到展示图片数组中
              for (const item of imgList) {
                this.uploadList.push(item)
              }
              this.$set(this.billForm, 'invoice_info', item.invoice_info)
            } else {
              this.$set(this.billForm, 'invoice_info', [])
            }
            // 如果获取的采购单为null，则设置空数组，否则会报错
            if (item.purchase_id_array) {
              this.$set(this.billForm, 'purchase_id_array', item.purchase_id_array)
              this.showShopSelect = this.shopSelect.filter((val, index) => {
                for (const pid of item.purchase_id_array) {
                  if (pid == val.order_number) {
                    return pid == val.order_number
                  }
                }
              })
            } else {
              this.$set(this.billForm, 'purchase_id_array', [])
            }
            // 如果获取的采购退货单为null，则设置空数组，否则会报错
            if (item.purchase_return_array) {
              this.$set(this.billForm, 'return_id_array', item.purchase_return_array)
              this.showReturnSelect = this.returnSelect.filter((val, index) => {
                for (const pid of item.purchase_return_array) {
                  if (pid == val.order_number) {
                    return pid == val.order_number
                  }
                }
              })
            } else {
              this.$set(this.billForm, 'return_id_array', [])
            }
          }
        })
      }
    },
    // 删除出库单
    deleteWare(index) {
      this.showShopSelect.splice(index, 1)
      this.postShopSelect.splice(index, 1)
      this.billForm.purchase_id_array.splice(index, 1)
      this.$Message.success('删除成功')
      this.isDeleteWare = [false, undefined]
    },
    // 确认关联销售订单
    okSaleSelect() {
      this.billForm.purchase_id_array = []
      this.showShopSelect = []
      for (const item of this.postShopSelect) {
        this.billForm.purchase_id_array.push(item)
        this.showShopSelect.push(
          this.allShopSelect.find(val => {
            return item == val.order_number
          })
        )
      }
      console.log(this.allShopSelect)
      this.selectShopModal = false
    },
    // 展示采购退货单弹窗并刷新
    showReturnTable() {
      this.selectReturnModal = true
      this.selectReturn()
    },
    /* 规定图片格式 */
    onFormatError() {
      this.$Message.error('只允许上传gif,jpg,jpeg,png格式的图片')
    },
    /* 上传图片成功的回调 */
    handleSuccessQuill(res) {
      if (this.uploadList.length < 5 && res.data.length + this.uploadList.length < 6) {
        for (const item of res.data) {
          let invoice_key = item.file_key
          let invoice_name = item.file_name
          let invoice_url = item.file_url
          let obj = { invoice_key, invoice_name, invoice_url }
          let imgObj = { invoice_key, invoice_name }
          this.billForm.invoice_info.push(imgObj)
          this.uploadList.push(obj)
        }
      } else {
        this.$Message.error('图片最多上传5张')
      }
      // 调接口 把key返回
    },
    /* 删除提示 */
    sureDelete(index) {
      this.deleteVisible = true
      this.imgIndex = index
    },
    /* 删除图片 */
    deleteImg() {
      // 将展示里的数组和实际上传图片的数组数据分别删除
      this.uploadList.splice(this.imgIndex, 1)
      this.billForm.invoice_info.splice(this.imgIndex, 1)
      this.deleteVisible = false
    },
    /* 展示图片 */
    showImg(url, index) {
      this.visible = true
      this.imgUrl = url
      this.imgIndex = index
    },
    /* 提交数据 */
    submitData() {
      // let flag = true
      let return_id_array = Array.from(this.billForm.return_id_array)
      let purchase_id_array = Array.from(this.billForm.purchase_id_array)
      let invoice_info = Array.from(this.billForm.invoice_info)
      let invoice_time = this.billForm.invoice_time
      invoice_time = invoice_time ? this.$moment(invoice_time).format('YYYY-MM-DD') : null
      let invoice_amount = this.billForm.invoice_amount ? this.billForm.invoice_amount : null
      if (invoice_time && invoice_amount >= 0) {
        let obj = { invoice_time, invoice_amount }
        if (return_id_array.length) {
          this.$set(obj, 'purchase_return_array', return_id_array)
        }
        if (purchase_id_array.length) {
          this.$set(obj, 'purchase_id_array', purchase_id_array)
        }
        if (invoice_info.length) {
          this.$set(obj, 'invoice_info', invoice_info)
        }
        if (this.type == '3') {
          // 编辑
          obj.invoice_no = this.billForm.invoice_no
          this.$http.put(this.$api.addBillInformation, obj).then(res => {
            if (res.status) {
              // this.$Message.success(res.message)
              this.usage_id_array = []
              this.purchase_id_array = []
              this.invoice_info = []
              this.$router.push('/billingInformation')
            } else {
              this.$Message.error(res.message)
            }
          })
        } else {
          // 新增
          this.$http.post(this.$api.addBillInformation, obj).then(res => {
            if (res.status) {
              this.$Message.success(res.message)
              this.usage_id_array = []
              this.purchase_id_array = []
              this.invoice_info = []
              this.$router.push('/billingInformation')
            } else {
              this.$Message.error(res.message)
            }
          })
        }
      } else {
        if (!invoice_time) {
          this.$Message.error('开票时间不能为空')
        }
        if (invoice_amount < 0) {
          this.$Message.error('发票金额不得小于0')
        }
      }
      this.submitVisible = false
      sessionStorage.setItem('updateList','0')
    },
    // 数字修改限制
    changeNum(item) {
      let str = item + ''
      let minLength = 0
      let maxlength = 0
      if (str.indexOf('.') !== -1) {
        // 小数点前的长度
        minLength = str.split('.')[1].length
        // 小数点后的长度
        maxlength = str.split('.')[0].length
      } else {
        maxlength = str.length
      }
      if (item >= 0 && maxlength < 9 && minLength < 3) {
        this.billForm.invoice_amount = item
      } else {
        this.$Message.error('请输入有效金额')
        // this.billForm.invoice_amount = null
        this.$set(this.billForm, 'invoice_amount', null)
      }
    },
    // 取消
    cancel() {
      this.visible = false
    },
    // 计算金额
    moneyCount() {
      let purchase_id_array = Array.from(this.billForm.purchase_id_array)
      let purchase_return_array = Array.from(this.billForm.return_id_array)
      let obj = { purchase_id_array, purchase_return_array }
      if (this.$route.query.type != '2') {
        this.$http.post(this.$api.getMoneyNumber, obj).then(res => {
          if (res.status) {
            this.$set(this.billForm, 'invoice_amount', res.data)
          }
        })
      }
    }
  },
  watch: {
    'billForm.purchase_id_array'() {
      this.moneyCount()
    },
    'billForm.return_id_array'() {
      this.moneyCount()
    }
  },
  async created() {
    this.type = this.$route.query.type //  type '1': 新增  '2':查看   '3':编辑
    this.bill_id = this.$route.query.id
    await this.selectShop()
    await this.selectReturn()
    await this.getBillInformation()
    /* 设置图片请求头 */
    this.url = http.http + '/public/upload/file'
    this.header = {
      Authorization: 'Bearer ' + localStorage.getItem('ohoqicbi==')
    }
  }
}
</script>

<style scoped lang="less">
.btnGroup {
  float: right;
  margin-top: 30px;
  text-align: right;
  .marginRight {
    margin: 0px 30px;
  }
}
@deep: ~'>>>';
@{deep}.ivu-tag {
  border-radius: 13px !important;
  max-width: 143px;
  text-overflow: ellipsis;
  overflow: hidden;
}
@{deep}.ivu-modal-content {
  img {
    width: 50%;
    height: 50%;
  }
}
/deep/ .ivu-badge-count {
  position: absolute;
  left: -15px;
  top: -15px;
}

.imgTit {
  cursor: pointer;
  display: inline-block;
  white-space: nowrap;
  width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: rgb(19, 148, 248);
  vertical-align: bottom;
}
.uploading {
  // height: 36px;
  line-height: 32px;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  font-size: 14px;
  position: relative;
  padding-left: 7px;

  display: flex;
  flex-wrap: wrap;

  .tip {
    font-size: 14px;
    padding: 0 0 0 4px;
    color: #c5c8ce;
    cursor: default;
    user-select: none;
    line-height: 32px;
  }
  .upload {
    width: 52px;
    height: 100%;
    background-color: #f4f4f4;
    border-left: 1px solid #e3e3e3;
    float: right;
    text-align: center;
    font-size: 14px;
  }
  .itemSpan {
    border: 1px solid #dddddd;
    border-radius: 13px;
    padding: 1px 10px;
    margin: 3px 10px 3px 0;
    display: inline-block;
    // height: 20px;
    // cursor: pointer;
    span {
      border: 0;
      text-decoration: none;
      color: #333333;
      max-width: 80px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    i {
      font-style: normal;
      padding-left: 10px;
      cursor: pointer;
    }
  }
}
.heightAuto {
  height: auto;
}
</style>
